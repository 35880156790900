import { PlaceItemInterface } from "@/models/order/PlaceItem.interface";
import { FormattedRouteInterface } from "@/models/order/FormattedRoute.interface";
import { generateId } from "@/utils/generateId";
import { getAddressKey, getFormattedDateTime } from "@/utils/formattedDateTime";

/**
 * Сортирует адреса погрузки и выгрузки на основе их временных меток и формирует маршрут.
 *
 * @param {PlaceItemInterface[]} places - Массив мест, содержащий адреса погрузки и выгрузки.
 *
 * @returns {FormattedRouteInterface[]} - Отсортированный массив адресов погрузки и выгрузки,
 *  представленный в формате `FormattedRouteInterface`, где каждый адрес содержит
 *  uid, адрес, дату и время, а также тип (0 - погрузка, 1 - выгрузка).
 *
 * Логика работы:
 * 1. Инициализируются два объекта: `loadingAddresses` и `unloadingAddresses` для хранения
 *    адресов погрузки и выгрузки соответственно.
 *
 * 2. Для каждого места в массиве `places`:
 *    - Генерируется уникальный идентификатор `placeUid`.
 *    - Перебираются адреса погрузки, и для каждого адреса погрузки происходит:
 *      - Перебор связанных адресов выгрузки.
 *      - Форматируется дата и время для каждого адреса.
 *      - Создаются ключи для адресов погрузки и выгрузки, состоящие из полного адреса и
 *        отформатированного времени.
 *
 *      - Если адрес погрузки еще не добавлен в `loadingAddresses`, он добавляется с
 *        соответствующими данными.
 *      - Если адрес выгрузки еще не добавлен в `unloadingAddresses`, он добавляется
 *        аналогично.
 *
 * 3. После обработки всех мест:
 *    - Все адреса погрузки и выгрузки добавляются в массив `newPlaces`.
 *
 * 4. Затем адреса сортируются по времени и типу (погрузка перед выгрузкой), чтобы
 *    подготовить их к формированию маршрута.
 *
 * 5. Далее, маршрут формируется, добавляя сначала точки погрузки, а затем соответствующие
 *    точки выгрузки, если все заказы на погрузку были загружены.
 *
 * 6. Пробегая по отсортированному массиву, проверяется, все ли заказы для точки выгрузки
 *    были загружены. Если да, точка выгрузки добавляется в маршрут.
 *
 * 7. В конце маршрут сортируется по индексу адреса и возвращается как результат.
 */
const sortPlacesByRoute = (
  places: PlaceItemInterface[]
): FormattedRouteInterface[] => {
  const newPlaces: FormattedRouteInterface[] = [];
  const loadingAddresses: Record<string, FormattedRouteInterface> = {};
  const unloadingAddresses: Record<string, FormattedRouteInterface> = {};

  places.forEach(place => {
    const placeUid = generateId();
    const loadingAddress = place.loadingAddress;
    const unloadingAddress = place.unloadingAddress;

    const loadingKey = getAddressKey(
      loadingAddress.address.value,
      loadingAddress.datePeriod
    );
    const unloadingKey = getAddressKey(
      unloadingAddress.address.value,
      unloadingAddress.datePeriod
    );

    if (!loadingAddresses[loadingKey]) {
      const loadingDate = new Date(
        `${loadingAddress.datePeriod.dateFrom}T${loadingAddress.datePeriod.hourFrom}`
      );

      loadingAddresses[loadingKey] = {
        ids: [placeUid],
        fullDateTime: getFormattedDateTime(loadingAddress.datePeriod),
        address: loadingAddress.address.value,
        addressIndex: loadingAddress.address.data.addressIndex,
        dateTime: loadingDate,
        uid: loadingAddress.uid,
        type: 0
      };
    } else {
      loadingAddresses[loadingKey].ids.push(placeUid);
    }

    if (!unloadingAddresses[unloadingKey]) {
      const unloadingDate = new Date(
        `${unloadingAddress.datePeriod.dateFrom}T${unloadingAddress.datePeriod.hourFrom}`
      );

      unloadingAddresses[unloadingKey] = {
        ids: [placeUid],
        fullDateTime: getFormattedDateTime(unloadingAddress.datePeriod),
        address: unloadingAddress.address.value,
        addressIndex: unloadingAddress.address.data.addressIndex,
        dateTime: unloadingDate,
        uid: unloadingAddress.uid,
        type: 1
      };
    } else {
      unloadingAddresses[unloadingKey].ids.push(placeUid);
    }
  });

  for (const key in loadingAddresses) {
    newPlaces.push(loadingAddresses[key]);
  }
  for (const key in unloadingAddresses) {
    newPlaces.push(unloadingAddresses[key]);
  }

  const sortedPlacesByLoadingType = newPlaces.sort((a, b) => {
    if (a.dateTime.getTime() === b.dateTime.getTime()) {
      return a.type > b.type ? -1 : a.type < b.type ? 1 : 0;
    }
    return a.dateTime.getTime() > b.dateTime.getTime() ? 1 : -1;
  });

  const route: FormattedRouteInterface[] = [];
  let notUnloadedRoutePoints: FormattedRouteInterface[] = [];

  for (let i = 0; i < sortedPlacesByLoadingType.length; i++) {
    const routePoint = sortedPlacesByLoadingType[i];

    if (routePoint.type === 0) {
      route.push(routePoint);
      const unloadingPoint = notUnloadedRoutePoints.find(point =>
        point.ids.some(id => routePoint.ids.includes(id))
      );

      if (!unloadingPoint) continue;

      const allOrdersAreLoaded = unloadingPoint.ids.every(id =>
        route.some(r => r.type === 0 && r.ids.includes(id))
      );

      if (allOrdersAreLoaded) {
        route.push(unloadingPoint);
        notUnloadedRoutePoints = notUnloadedRoutePoints.filter(
          point => point !== unloadingPoint
        );
      }
    } else {
      const allOrdersAreLoaded = routePoint.ids.every(id =>
        route.some(r => r.type === 0 && r.ids.includes(id))
      );

      if (allOrdersAreLoaded) {
        route.push(routePoint);
      } else {
        notUnloadedRoutePoints.push(routePoint);
      }
    }
  }

  route.forEach((r, i) => {
    if (r.addressIndex <= 0) r.addressIndex = i;
  });

  return route.sort((a, b) => a.addressIndex - b.addressIndex);
};
export default sortPlacesByRoute;
