import { ref } from "vue";
import { FormattedRouteInterface } from "@/models/order/FormattedRoute.interface";
import { OrderItemInterface } from "@/models/order/OrderItem.interface";
import sortPlacesByRoute from "@/utils/sortPlacesByRoute";
import { validateRoute } from "@/components/order/Route/utils/validateRoute";
import useVuelidate from "@vuelidate/core";
import { helpers } from "@vuelidate/validators";
import { PlaceItemInterface } from "@/models/order/PlaceItem.interface";

export default function useRoute(order: OrderItemInterface) {
  const points = ref<FormattedRouteInterface[]>([]);
  const errorMessage = ref<string | null>(null);
  const invalidPoints = ref<Set<string>>(new Set());

  const v$ = useVuelidate(
    {
      errorMessage: {
        routeIsInvalid: helpers.withMessage(
          errorMessage.value || "",
          (error: string | null) => !error
        )
      }
    },
    { errorMessage }
  );

  const updateRouteIndexes = () => {
    points.value.forEach((p, i) => {
      const key = p.address + p.fullDateTime;
      order.route[key] = i + 1;
    });
  };

  const mapPlaceToSortedPoints = (places: PlaceItemInterface[]) => {
    points.value = sortPlacesByRoute(places);
    updateRouteIndexes();
  };

  const validatePoints = () => {
    invalidPoints.value.clear();
    try {
      validateRoute(order.places, points.value as FormattedRouteInterface[]);
      errorMessage.value = null;
    } catch (error) {
      error.addressUid?.forEach((uid: string) => invalidPoints.value.add(uid));
      errorMessage.value = error.message;
    }
  };

  const onDragEnd = () => {
    updateRouteIndexes();
    validatePoints();
  };

  const isInvalidPoint = (uid: string) => {
    return invalidPoints.value.has(uid);
  };

  return {
    v$,
    points,
    errorMessage,
    isInvalidPoint,
    mapPlaceToSortedPoints,
    validatePoints,
    onDragEnd
  };
}
