import { isBefore } from "date-fns";
import { FormattedRouteInterface } from "@/models/order/FormattedRoute.interface";
import { PlaceItemInterface } from "@/models/order/PlaceItem.interface";

/**
 * Класс для обработки ошибок валидации маршрута.
 * Наследует стандартный класс Error и добавляет информацию о проблемных адресах.
 */
class RouteValidationError extends Error {
  public addressUid?: string[];

  /**
   * Создает новый экземпляр RouteValidationError.
   *
   * @param {string} message - Сообщение об ошибке, описывающее проблему.
   * @param {string[]} [addressUid] - Массив уникальных идентификаторов адресов, связанных с ошибкой.
   */
  constructor(message: string, addressUid?: string[]) {
    super(message);
    this.name = "RouteValidationError";
    this.addressUid = addressUid; // Сохраняем uid адресов
  }
}

/**
 * Объект, содержащий валидаторы для проверки корректности маршрутов.
 */
export const validators = {
  /**
   * Проверяет последовательность дат и времени для всех точек маршрута.
   *
   * @param {FormattedRouteInterface[]} points - Массив всех точек маршрута.
   * @throws {RouteValidationError} Если последовательность дат некорректна.
   */
  validateDates: (points: FormattedRouteInterface[]) => {
    let lastDateTime: Date | null = null;
    let lastPointUid: string | null = null;

    for (const point of points) {
      const pointUid = point.uid;
      const pointDateTime = new Date(point.dateTime);

      // Проверяем порядок по датам для всех точек
      if (lastDateTime && isBefore(pointDateTime, lastDateTime)) {
        throw new RouteValidationError(
          "Дата или время не соответствует последовательности маршрута. Пожалуйста, измените дату/время или последовательность.",
          [pointUid, lastPointUid as string]
        );
      }

      // Обновляем последнюю дату и UID
      lastDateTime = pointDateTime;
      lastPointUid = pointUid;
    }
  },

  /**
   * Проверяет корректность последовательности погрузки и выгрузки внутри каждого места.
   *
   * @param {FormattedRouteInterface[]} points - Массив всех точек маршрута.
   * @throws {RouteValidationError} Если последовательность типов некорректна.
   */
  validateTypesWithinPlaces: (points: FormattedRouteInterface[]) => {
    // Извлекаем все уникальные идентификаторы мест
    const allPlaceIds = new Set<string>();
    points.forEach(point => {
      point.ids.forEach(id => allPlaceIds.add(id));
    });

    // Проверяем маршрут для каждого места
    allPlaceIds.forEach(placeId => {
      let hasLoading = false;

      // Фильтруем точки, связанные с текущим placeId
      const pointsForPlace = points.filter(point =>
        point.ids.includes(placeId)
      );

      const loadingPointUid = pointsForPlace.find(p => p.type === 0)?.uid;

      for (const point of pointsForPlace) {
        const pointUid = point.uid;
        if (point.type === 0) {
          hasLoading = true;
        } else if (point.type === 1) {
          if (!hasLoading) {
            // Выгрузка до погрузки в рамках одного места
            throw new RouteValidationError(
              "Маршрут построен некорректно: выгрузка не может быть раньше погрузки. Поменяйте последовательность точек.",
              [pointUid, loadingPointUid] as string[]
            );
          }
        }
      }
    });
  }
};

/**
 * Метод для проверки валидности маршрутов, который вызывает внутренние валидаторы.
 *
 * @param {PlaceItemInterface[]} places - Массив мест, содержащих адреса для проверки.
 * @param {FormattedRouteInterface[]} points - Массив точек маршрута для проверки.
 */
export const validateRoute = (
  places: PlaceItemInterface[],
  points: FormattedRouteInterface[]
) => {
  // Валидация типов внутри каждого места
  validators.validateTypesWithinPlaces(points);
  // Валидация дат и времени для всех точек
  validators.validateDates(points);
};
