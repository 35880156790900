import { RequestStatusEnum } from "@/models/global/RequestStatus.enum";
import { computed, ref } from "vue";
import { UseOrderCreationFuncInterface } from "@/models/order/compositions/UseOrderCreationFunc.interface";
import OrderService from "@/models/order/Order.service";
import { generateId } from "@/utils/generateId";
import TemplateService from "@/models/order/Template.service";
import { TemplateFormInterface } from "@/models/store/TemplatesTableHeader.interface";
import { useUserStore } from "@/store/useUserStore";

const useOrderCreation: UseOrderCreationFuncInterface = (v$, reqData) => {
  const reqStatus = ref<RequestStatusEnum>(RequestStatusEnum.Pending);
  const reqMessage = ref<string>("");
  const lastSaveOperation = ref<string>("");
  const reqLoading = computed(
    () => reqStatus.value === RequestStatusEnum.Loading
  );
  const loadingProgress = ref<number>(0);
  const _orderService = new OrderService();
  const _templateService = new TemplateService();
  const _userStore = useUserStore();

  const templates = computed<TemplateFormInterface[]>(() => {
    return _userStore.templates;
  });

  function monitorOperationProgress(operationId: string): Promise<void> {
    return new Promise<void>((resolve, reject) => {
      const checkProgress = () => {
        _orderService
          .getOrderUploadOperationProgress(operationId)
          .then(response => {
            if (
              !response.isSuccess ||
              !response.entity ||
              response.entity.progress == null
            ) {
              reject(new Error("Ошибка сервера или прогресс не определён"));
              return;
            }

            const { progress } = response.entity;
            loadingProgress.value = progress;

            if (progress === -1) {
              reject(new Error("Ошибка загрузки"));
              return;
            }

            if (progress >= 100) {
              reqMessage.value = "Успех!";
              reqStatus.value = RequestStatusEnum.Success;
              reqData.orderGuid.value = generateId();
              resolve();
              return;
            }

            setTimeout(checkProgress, 500);
          })
          .catch(() => {
            reject(new Error("Ошибка выполнения"));
          });
      };

      checkProgress();
    });
  }

  const create = async () => {
    if (v$.value.$invalid) {
      v$.value.$touch();
      return;
    }

    reqStatus.value = RequestStatusEnum.Loading;
    reqMessage.value = "";
    lastSaveOperation.value = "create";

    try {
      const res = await _orderService.createOrder(
        reqData.order.value,
        {
          withInsurance: reqData.withInsurance.value,
          orderGuid: reqData.orderGuid.value,
          insuranceInfo: reqData.insuranceInfo.value
        },
        reqData.order.value.type === "Ftl"
      );

      if (res.isSuccess) {
        loadingProgress.value = 0;
        await monitorOperationProgress(res.entity);
      } else {
        reqMessage.value = `Ошибка сервиса: ${res.message}`;
        reqStatus.value = RequestStatusEnum.Error;
        return;
      }
    } catch (e) {
      reqStatus.value = RequestStatusEnum.Error;
      reqMessage.value = "Ошибка сервиса!";
    } finally {
      loadingProgress.value = 0;
      reqData.withInsurance.value = false;
    }
  };

  const saveTemplateAs = async (param: { id: string | null; name: string }) => {
    reqStatus.value = RequestStatusEnum.Loading;
    reqMessage.value = "";
    lastSaveOperation.value = "saveTemplateAs";

    try {
      const res = await _templateService.saveTemplateAs(
        reqData.order.value,
        {
          withInsurance: reqData.withInsurance.value,
          orderGuid: reqData.orderGuid.value,
          insuranceInfo: reqData.insuranceInfo.value
        },
        reqData.order.value.type === "Ftl",
        param.id,
        param.name
      );

      if (res.isSuccess) {
        reqMessage.value = "Успех";
        reqStatus.value = RequestStatusEnum.Success;
      } else {
        reqMessage.value = `Ошибка сервиса: ${res.message}`;
        reqStatus.value = RequestStatusEnum.Error;
        return;
      }

      await _userStore.getTemplates();
    } catch (e) {
      reqStatus.value = RequestStatusEnum.Error;
      reqMessage.value = "Ошибка сервиса!";
    }
  };

  const createOrderWithInsurance = async () => {
    if (Number(reqData.insurancePremium) < 1) {
      reqMessage.value = "Не расчитана страховая премия";
      reqStatus.value = RequestStatusEnum.Error;
      return;
    }
    reqData.withInsurance.value = true;
    await create();
  };

  const applySelectedTemplate = async (
    selectedTemplate: TemplateFormInterface | null
  ): Promise<void> => {
    if (selectedTemplate == null) return;

    try {
      const loadTemplateResponse = await _templateService.loadTemplate(
        selectedTemplate,
        templates.value
      );
      if (
        !loadTemplateResponse.isSuccess ||
        loadTemplateResponse.templateData == null
      ) {
        reqMessage.value = `Ошибка сервиса: ${loadTemplateResponse.message}`;
        reqStatus.value = RequestStatusEnum.Error;
        return;
      }

      reqData.order.value = loadTemplateResponse.templateData;
    } catch (e) {
      reqMessage.value = "Ошибка сервиса!";
      reqStatus.value = RequestStatusEnum.Error;
    }
  };

  return {
    reqStatus,
    reqMessage,
    reqLoading,
    loadingProgress,
    create,
    createOrderWithInsurance,
    saveTemplateAs,
    lastSaveOperation,
    applySelectedTemplate
  };
};

export default useOrderCreation;
