import { InsuranceRequestInterface } from "@/models/order/InsuranceRequest";
import { InsuranceResponseInterface } from "@/models/order/InsuranceResponse";
import { ErrorResponseInterface } from "@/models/api/ErrorResponse.interface";
import { DataResponseInterface } from "@/models/api/DataResponse.interface";
import api from "./baseApi";
import { AxiosResponse } from "axios";

export async function insuranceCalculation(
  req: InsuranceRequestInterface
): Promise<
  ErrorResponseInterface | DataResponseInterface<InsuranceResponseInterface>
> {
  const res: AxiosResponse<
    ErrorResponseInterface | DataResponseInterface<InsuranceResponseInterface>
  > = await api.post("/Insurance", req);

  return res.data;
}
